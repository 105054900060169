import { useParams, useNavigate } from 'react-router-dom';
import { AddCircleOutline } from '@mui/icons-material';
import { makeStyles } from "@mui/styles"
import Box from '@mui/material/Box';
import React from 'react';

import RecentTemplatesAnalytics from 'components/BatchAnalytics/RecentTemplatesAnalytics';
import BestPerformers from 'components/BatchAnalytics/BestPerformers';
import UserAnalytics from 'components/BatchAnalytics/UserAnalytics';
import BatchStats from 'components/BatchAnalytics/BatchStats';
import BatchInfo from 'components/BatchAnalytics/BatchInfo';
import ShadowBox from 'components/ShadowBox';
import { getBatchById } from 'services';
import { Typography } from '@mui/material';
import CustomButton from 'components/CustomButtons';

const useStyles = makeStyles(theme => ({
  followupContainer: {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "2rem", 
    rowGap: "4px",
    border: 'none', 
    marginBottom: theme.spacing(5),
    borderRadius: 4, position: 'relative',
    marginTop: theme.spacing(1),
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
  },
  ecllipse: {
    position: 'absolute',
    right: 0, bottom: 0,
    height: 140
  },
  analyticsRoot: {
    display: "flex",
    flexDirection: "column",
    minHeight: 'calc(100vh - 56px)',
    maxWidth: 'calc(100% - 8px)',
    paddingBottom: theme.spacing(6)
  }
}));

function FollowupCard({ batchId }) {
  const classess = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classess.followupContainer}>
      <img
        src={'https://assets.languify.in/images/ecllipse.svg'}
        alt="followup" className={classess.ecllipse}
      />
      <Box className={classess.heading}>
        <Typography variant='h4-medium'>
          Boost your student with Follow up assessment
        </Typography>
      </Box>
      <Typography variant='h6-medium' color='neutral.clr-600'>
        Personalized assessment focusing weak points of every learner
      </Typography>
      <CustomButton
        sx={{ marginTop: '20px'}}
        size='medium'
        startIcon={<img src='https://assets.languify.in/images/edit_followup.svg' alt='edit' />}
        onClick={() => navigate(`/batches/${batchId}/create-followup`)}
      >
        Create follow up assessment
      </CustomButton>
    </Box>
  );
}

function BatchAnalytics(props) {
  const classes = useStyles();
  const { id } = useParams();

  const [batch, setBatch] = React.useState({});

  React.useEffect(() => {
    getBatchById(id, { domain: true })
      .then(template => setBatch(template))
      .catch(console.error)
  }, [id]);

  return (
    <Box className={classes.analyticsRoot}>
      <BatchInfo batch={batch} />
      <Box display="flex" gap={2}>
        <BestPerformers />
        <BatchStats />
      </Box>

      <FollowupCard batchId={id} />

      <ShadowBox sx={{ mt: 0 }}>
        <RecentTemplatesAnalytics />
        <UserAnalytics batch={batch} />
      </ShadowBox>
    </Box>
  );
}

export default BatchAnalytics;