import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles(theme => ({
    container: {
      boxShadow: "0px 0px 3px 0px #00000080",
      borderRadius: theme.spacing(1),
      background: "#FFFFFF",
      position: "relative",
      height: "200px",
      overflow: "hidden",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    bigCircle: {
      height: 232,
      width: 232,
      backgroundColor: "#F0F5FA",
      borderRadius: "50%",
      position: "absolute",
      top: -116,
      left: -116,
      zIndex: 1
    },
    smallCircle: {
      height: 76,
      width: 76,
      backgroundColor: "#E5F1FF",
      borderRadius: "50%",
      position: "absolute",
      top: -38,
      left: -38,
      zIndex: 2
    },
    heading: {
      background: 'linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      zIndex: 1,
      textAlign: "left"
    },
    box: {
      zIndex: 3,
      position: "inherit",
      padding: theme.spacing(7,0,6,10),
      display: "flex",
      gap: theme.spacing(2.5),
      flexDirection: "column",
      width: "60%",
      overflow: "hidden"
    },
    content: {
      fontSize: "19px",
      fontWeight: 600,
      lineHeight: "23.56px",
      textAlign: "left",
      color: "#8692A3"
    },
    contentbox: {
      minWidth: "100%",
      transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    swipeBox: {
      display: 'flex',
      flexDirection: 'row',
      transition: 'transform 0.5s ease-in-out',
    },
    none: {
      opacity: 0
    },
    right: {
      width: "30%",
      height: "200px",
      backgroundSize: "contain",
      position: "relative",
      display: "flex",
      flexDirection: "row-reverse",
      padding:theme.spacing(3.5, 5, 0, 0),
    },
    image: {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      objectFit: "contain",
    },
    bike: {
      position: "absolute",
      bottom: 20,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center"
    },
    bikeImage: {
      animation: "$wave 3s linear infinite",
      width: 183,
      height: 102,
      alignItems: "flex-end"
    },
    '@keyframes wave': {
      '0%': {
        transform: "translate3d(-25px, 0, 0)",
      },
      '50%': {
        transform: "translate3d(25px, 0, 0)",
      },
      '100%': {
        transform: "translate3d(-25px, 0, 0)",
      },
    },
}))

const data = [
    "82% of candidates who practiced mock interviews reported feeling more prepared for real interviews (CareerPlug)",
    "55% of candidates improved their ability to articulate their skills and experiences after mock interviews (Monster)",
    "Practicing in multiple mock interviews reduces interview anxiety by 40% (Psychology Today)",
    "70% of hiring managers found candidates interview ready who had practiced mock interviews (Robert Half)",
    "90% of candidates felt more confident in negotiating salaries after mock interviews (Salary.com)"
]

export default function Banner() {
    const classes = useStyle();
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
      const timer = setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % data.length);
      }, 5000);

      return () => clearTimeout(timer);
    }, [activeIndex]);

    return (
      <Box className={classes.container}>
        <Box className={classes.smallCircle}></Box>
        <Box className={classes.bigCircle}></Box>
        <Box className={classes.box}>
          <Typography className={classes.heading} variant="h3-medium">
            Make your students’ Interview journey easy with mock preparation
          </Typography>
          <Box
            className={classes.swipeBox}
            style={{ transform: `translateX(-${activeIndex * 100}%)` }}
          >
            {data.map((value, index) => {
              return (
                <Box
                  key={index}
                  className={`${classes.contentbox} ${index===activeIndex?'':classes.none}`}
                >
                  <Typography className={classes.content}>
                    "{value}"
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
          <Box className={classes.right}>
            <img
              src="https://assets.languify.in/images/illustration+animation.png"
              alt="background"
              className={classes.image}
            />
            <Box className={classes.bike}>
              <img
                src="https://assets.languify.in/images/motercycle.png"
                alt="bike"
                className={classes.bikeImage}
              />
            </Box>
          </Box>
      </Box>
    )
}


