import React from 'react';
import { useTheme } from '@mui/material/styles';
import { TableCell, TableRow, Typography } from '@mui/material';

const sx = {
  rows: {
    whiteSpace: 'nowrap',
    minHeight: '40px !important'
  },
  stickyColumn: {
    position: "sticky",
    backgroundColor: "#fff",
  },
  actionColumn: {
    position: "sticky",
    backgroundColor: "#fff",
    right: '-1px',
    display: 'flex',
    gap: '2px',
    justifyContent: 'center',
    alignItem: 'center',
    padding: '3px 0px'
  },
  analyticsColumn: {
    whiteSpace: 'nowrap',
    position: "sticky",
    backgroundColor: "#fff",
    right: '40px',
    zIndex: 10,
  },
};

const Row = ({ 
  rowData, columns, rowNo, handleRowSelection, showRowSelectCheckbox= false, rowSelectionModel=[] , onRowClick
}) => {
  
  const theme = useTheme();
  return (
    <>
      <TableRow key={rowNo}
          onClick={() => {
            if (onRowClick) onRowClick(rowData); 
            handleRowSelection(rowData);
          }}
        sx={
          { 
            '& td': { 
              backgroundColor: rowSelectionModel.includes(rowData._id) ? '#E3F4FF' : null,
              padding: '3px 5px', height: '38px'
            },
            '&:hover td' : {
              backgroundColor: rowSelectionModel.includes(rowData._id) ? 
                  '#E3F4FF' : theme.palette.neutral['clr-50'],
              cursor: 'pointer'
            },
            ...sx.rows}}>
        {
          columns.map((col, index) => {
            if (col?.type === 'actions') {
              return (
                <TableCell key={index} sx={sx.actionColumn}>
                  {
                    col.getActions({
                      column: columns, row: rowData, id: rowData._id,
                      selected: rowSelectionModel.includes(rowData._id) || false
                      })
                  }
                </TableCell>
              )
            }
            if (col?.type === 'select') {
              return (
                <TableCell key={index} 
                  sx={{ ...(sx.stickyColumn), left: 0}}
                >
                  {
                    col.getActions({
                      column: columns, row: rowData, id: rowData._id,
                      selected: rowSelectionModel.includes(rowData._id) || false
                      })
                  }
                </TableCell>
              )
            }
            else {
              return (
                <TableCell
                  display='flex'
                  align={col?.align || 'start'}
                  sx={
                    (index===0)
                    ? { ...(sx.stickyColumn), left: 0} 
                    : (showRowSelectCheckbox && index===1)
                      ? { ...(sx.stickyColumn), left: '25px'} 
                        : col?.fixed
                          ? { ...(sx.analyticsColumn) } : null 
                    }
                  style={{
                    minWidth: col?.minWidth,
                    maxWidth: col?.maxWidth || 'fit-content',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Typography
                    variant='body01-medium'
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '100%'
                    }}
                  >
                    {
                      col?.renderCell
                        ? col.renderCell({ row: rowData, field: col?.field, index: rowNo })
                        : rowData?.[col?.field]
                    }
                  </Typography>
                </TableCell>
              )
            }
          })
        }
      </TableRow>
    </>
  )
}

export default Row