import { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles'
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Collapse';

import ShadowBox from 'components/ShadowBox';
import { Session } from 'utils';
import InPrepHeaderPreview from './InPrepHeaderPreview';
import SlideControl from './SlideControl';
import ImageUploadButton from './ImageUploadButton';
import ActionButtons from 'components/ActionButtons';
import { useSnackbar } from 'contexts';
import { updateInprepLogo } from 'services';

const useStyles = makeStyles(theme => ({
  cropContainer: {
    // display: 'none',
    position: 'relative',
    width: '100%',
    height: 60,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 60,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width : "536px"
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
}));

const sx = {
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  editButton: {
    position: "absolute",
    right: 32,
    bottom: 24,
    backgroundColor: '#d3d3d3cc',
    zIndex: 1
  },
  profileButton: {
    width: 150,
    height: 150,
    minWidth: 150,
    minHeight: 150,
    maxWidth: 150,
    maxHeight: 150,
    backgroundColor: "#E4E3E8",
    borderRadius: "50%",
    border: "1px solid #ffffff73",
    overflow: "hidden",
    p: 0
  },
  avatar: { width: 150, height: 150 },
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

function InprepLogoEditor() {
  const classes = useStyles();
  const snackbar = useSnackbar();

  const [saving, setSaving] = useState(false);
  const [edit, setEdit] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [prevScale, setPrevScale] = useState(1);
  const [prevRotation, setPrevRotation] = useState(0);
  const [prevTranslate, setPrevTranslate] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (edit) {
      setPrevScale(scale);
      setPrevRotation(rotation);
      setPrevTranslate(translate);
    }
  }, [edit]);

  const discardChanges = () => {
    if (scale !== prevScale) setScale(prevScale);
    if (rotation !== prevRotation) setRotation(prevRotation);
    if (translate.x !== prevTranslate.x || translate.y !== prevTranslate.y) {
      setTranslate(prevTranslate);
    }
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      setImageFile(file);

      let imageDataUrl = await readFile(file)

      setImageSrc(imageDataUrl);
      setIsNew(true);
    }
  }

  const saveChanges = async () => {
    try {
      setSaving(true);

      const transformData = {
        rotation, scale, translateX: translate.x, translateY: translate.y,
      };

      const logoUrl = await updateInprepLogo({
        isNew, logo: imageFile, ...transformData
      });

      Session.setInprepLogoAndTransform(logoUrl, transformData);
    } catch (error) {
      snackbar.showSnackbar(error.message, 'error');
    } finally {
      setSaving(false);
      snackbar.success("Successfully saved logo!!");
    }
  };

  useEffect(() => {
    const { logoUrl, inprepLogoTransform } = Session.getInprepLogoAndTransform();

    if (logoUrl) setImageSrc(logoUrl);
    if (inprepLogoTransform) {
      setScale(+inprepLogoTransform.scale);
      setRotation(+inprepLogoTransform.rotation);
      setTranslate({
        x: +inprepLogoTransform.translateX,
        y: +inprepLogoTransform.translateY
      });
    }
    setIsNew(false);
  }, [edit])

  return (
    <ShadowBox sx={sx.root} >
      <Box display="flex" alignItems="center" mb={2} justifyContent='space-between'>
        <Typography variant='body01-bold'>
          Header Preview
        </Typography>
        <IconButton
          color="primary" onClick={() => setEdit(true)}
          style={{ visibility: edit ? 'hidden' : 'visible' }}
        >
          <EditIcon fontSize='small' />
        </IconButton>
      </Box>
    <Box display='flex'  boxShadow='0px 0px 3px 0px #00000080' >
      <InPrepHeaderPreview
          imageSrc={imageSrc}
          scale={scale}
          rotation={rotation}
          translate={translate}
        />
        <Grow in={edit}>
          <Box padding={7}>
            <div className={classes.controls}>
              <SlideControl
                label="Zoom"
                value={scale}
                min={0.1}
                max={3}
                step={0.01}
                onChange={setScale}
                defaultValue={1}
              />
              <SlideControl
                label="Rotate"
                value={rotation}
                min={-180}
                max={180}
                step={0.1}
                onChange={setRotation}
                track={false}
                defaultValue={0}
              />
              <SlideControl
                label="Shift-X"
                value={translate?.x || 0}
                min={-100}
                max={100}
                defaultValue={0}
                step={0.1}
                onChange={(x) => setTranslate(({ y }) => ({ x, y }))}
                track={false}
              />
              <SlideControl
                label="Shift-Y"
                value={translate?.y || 0}
                min={-60}
                max={60}
                defaultValue={0}
                step={0.1}
                onChange={(y) => setTranslate(({ x }) => ({ x, y }))}
                track={false}
              />
            </div>
            <Box display="flex">
              <ActionButtons
                loading={saving}
                primaryFirst
                primaryAction="Save"
                secondaryAction="Cancel"
                primaryActionVariant="primary"
                secondaryActionVariant="error"
                onSecondaryClick={discardChanges}
                onPrimaryClick={saveChanges}
              />
            </Box>
          </Box>
        </Grow>
        <ImageUploadButton onChange={onFileChange} />
      </Box>
    </ShadowBox>
  );
}

export default InprepLogoEditor;
