import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Box  from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import { Button, InputAdornment, ListSubheader, TextField } from '@mui/material';
import TitleTooltip from './TitleTooltip';

export const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography['body01-bold'],
    textTransform: 'capitalize', marginBottom: theme.spacing(2)
  },
  ".MuiSelect-nativeInput": {
    height: 44,
  },
  ".MuiSelect-root": {
    ...theme.typography['body01-medium'],
    height: '44px',
    '&:hover': {
      backgroundColor: theme.palette.neutral['clr-50']
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.neutral['clr-200'],
      '& fieldset': {
        border: '2px solid',
      },
    }
  },
  ".MuiSelect-select": {
    "& fieldset": {
      border: '1px solid',
      borderColor: theme.palette.neutral['clr-500']
    },
    "&:hover": {
      "& fieldset": {
        border: '1px solid',
      },
    },
  },
  paper: {
    boxShadow: "0px 2px 8px 0px #00000029"
  },
  groupList: (props) => ( {
    maxHeight: 300,
    overflowY: "auto",
    padding: '0px',
    "& li": {
      ...theme.typography['body01-medium'],
      height: '40px',
      borderBottom: `1px solid ${theme.palette.neutral['clr-200']}`,
      width: props.optionsWidth || '100%',
      overflow: "hidden",
    },
    "& li:hover": {
      backgroundColor: theme.palette.primary['clr-50'],
      height:'40px',
      minHeight : '40px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral['clr-200'],
        borderColor: theme.palette.neutral['clr-200'],
        '& fieldset': {
          border: '2px solid',
          borderColor: theme.palette.primary['clr-600'],
        },
      }
    },
    "& li.Mui-selected": {
      backgroundColor: `${theme.palette.primary['clr-400']} !important`,
      color: theme.palette.shades['clr-white-900']
    },
    "& li.Mui-selected:hover": {
      backgroundColor: theme.palette.primary['clr-400'],
      color: theme.palette.shades['clr-white-900']
    }
  }),
  category: {
    backgroundColor: '#fcf8f8', 
    height: 40, display: 'flex', alignItems: 'center',
    color: theme.palette.primary.main,
    fontWeight: '600', 
    border: '2px solid',
    borderColor: theme.palette.neutral.main,
    fontSize: '16px !important',
    borderWidth: '2px 0px 2px 0px',
  },
  textField: {
    display: 'block',
    borderRadius: theme.spacing(1),
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor : theme.palette.neutral['clr-600']
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '16px',
      color: `${theme.palette.neutral['clr-700']} !important`,
      height: 'fit-content',
      ...theme.typography['body01-medium'],
      padding: theme.spacing(3,2),
      backgroundColor: theme.palette.shades['clr-white-900']
    },
    '& .MuiOutlinedInput-root': {
      '&:hover' : {
        backgroundColor: theme.palette.shades['clr-white-900']
      },
      height: '36px !important',
      borderRadius: theme.spacing(1),
      fontSize: 16,
      '& fieldset': {
        borderColor: theme.palette.neutral['clr-200'],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.neutral['clr-200'],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary['clr-500'],
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral['clr-200'],
        '& fieldset': {
          border: '2px solid',
          borderColor: theme.palette.neutral['clr-400'],
        },
      }
    },
  },
  overflowText: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  truncatedTypography: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '1.4em', 
    ...theme.typography['body01-medium']
  },
  overflown: {
    '&:hover': {
      whiteSpace: 'normal',
      WebkitLineClamp: 2, 
      maxHeight: '2.8em', 
      ...theme.typography['body01-medium'],
      margin:theme.spacing(1,0)
    },
  },

  actionGroupList: (props) => ({
    "& li": {
      height: '44px !important'
    },
    "& li.Mui-selected": {
      color: theme.palette.shades['clr-black-900']
    },
    "& li.Mui-selected:hover": {
      color: theme.palette.shades['clr-black-900']
    }
  }),
  actionSelect: {
    position: 'relative',
  },
  actionButton: {
    width: '100%',
    textTransform: 'none',
    boxShadow: 'none',
    paddingLeft: 0,
    textAlign: 'start',
    ...theme.typography['body01-medium'],
    "&:hover": {
      backgroundColor: 'transparent'
    },
  },
  actionDropdownIcon: {
    cursor: 'pointer', position: 'absolute', 
    right: 10, 
    height: '100%', width: '32px',
    display: 'flex', alignItems: 'center',
    paddingLeft: '6px',
  },
  actionMenuItems: {
    padding: '0px',
  },
  actionMenuBtn: {
    width: '100%', height: '100%', 
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(0,4),
    backgroundColor: theme.palette.shades['clr-white-900'],
    '&:hover': {
      backgroundColor: theme.palette.primary['clr-50'],
    }
  },
  actionMenuBtnDisabled: {
    backgroundColor: theme.palette.neutral.disabled,
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.neutral.disabled,
    }
  }
}));

const IconComponent = (props) => {
  const classes = useStyles();

  return (
    <ExpandMoreIcon className={props.className + " " + classes.icon} />
  )
};

export function SimpleSelect({
  options, defaultValue, value, onSelect = () => { }, label = null, placeholder = 'Select option',
  loading = false, ...props
}) {
  const classes = useStyles();

  const menuProps = React.useMemo(() => ({
    classes: {
      paper: classes.paper,
      list: classes.groupList
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    MenuListProps: {
      style: {
        ...props.menuStyle
      }
    }
  }), []);

  return (
    <FormControl className={classes['.MuiFormControl-root']}>
      {
        label && <Typography className={classes.label}>{label}</Typography>
      }
      <>
        {
          (loading) ?
            <SelectSkeleton /> :
            <MuiSelect
              displayEmpty
              className={`${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} ${classes['.MuiSelect-select']}`}
              sx={{ minWidth: 200, ...(props.sx ? props.sx : {}) }}
              onChange={onSelect}
              renderValue={(selected) => {
                if (!selected || selected.length === 0) {
                  return placeholder;
                }
                const data = options.find((option) => option._id === selected);
                return data ? data.name : placeholder;
              }}
              value={value}
              defaultValue={defaultValue}
              inputProps={{ className: classes.input }}
              MenuProps={menuProps}
              IconComponent={IconComponent}
              {...props}
              id="select"
            >
              {
                options?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))
              }
            </MuiSelect>
        }
      </>
    </FormControl>
  );
};

const SelectSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" width="200px" height='44px' style={{ borderRadius: '4px' }} />
    </Box>
  )
}

export function AutoCompleteSelect({
  options,
  defaultValue,
  value,
  onSelect = () => {},
  label = null,
  placeholder = 'Select option',
  loading = false,
  width,
  optionsWidth,
  name,
  ...props
}) {
  const classes = useStyles({optionsWidth});
  const theme = useTheme()
  
  const [searchTerm, setSearchTerm] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const searchInputRef = React.useRef(null);

  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const preventDropdownClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDropdownOpen = () => {
    setOpen(true);
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 0);
  };

  const handleDropdownClose = () => {
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
  };

  const menuProps = React.useMemo(() => ({
    classes: {
      paper: classes.paper,
      list: classes.groupList
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    MenuListProps: {
      style: {
        ...props.menuStyle
      }
    }
  }), [classes.paper, classes.groupList, props.menuStyle]);

  const iconComponent = (iconProps) => (
    <ExpandMoreIcon className={`${iconProps.className} ${classes.icon}`} />
  );

  return (
    <FormControl className={classes['.MuiFormControl-root']}>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <TitleTooltip
            placement='bottom' 
            component= {name}
            disableHoverListener={name?.length < 22}
            positionStyle={{ top: '-10px !important', left: '0px !important' , zIndex:0 }}
          >
            <MuiSelect
              displayEmpty
              className={`${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} ${classes['.MuiSelect-select']}`}
              sx={{ minWidth: 200, width, ...(props.sx || {}) }}
              onChange={onSelect}
              renderValue={(selected) => {
                const data = filteredOptions.find((option) => option._id === selected);
                return selected?.length ? data?.name : placeholder;
              }}
              value={value}
              defaultValue={defaultValue}
              inputProps={{ className: classes.input }}
              IconComponent={iconComponent}
              open={open}
              onOpen={handleDropdownOpen}
              onClose={handleDropdownClose}
              MenuProps={menuProps}
              disableCloseOnSelect
              {...props}
              id="select"
            >
              <ListSubheader 
                className={classes.category} 
                style={{ 
                        height: '52px', backgroundColor: theme.palette.shades['clr-white-900'],
                        padding: theme.spacing(2) ,
                        "&:hover": {
                          backgroundColor: theme.palette.shades['clr-white-900'], 
                        }
                      }}
              >
                <TextField
                  fullWidth
                  placeholder="Search here..."
                  className={classes.textField}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onClick={preventDropdownClose}
                  onFocus={() => setOpen(true)}
                  inputRef={searchInputRef}
                  InputProps={{
                    className: classes.input,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src='https://assets.languify.in/images/search-icon.svg'
                          alt='search'
                          width={14}
                          height={14}
                        />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  onKeyDown={handleKeyDown}
                />
              </ListSubheader>

              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <MenuItem
                    key={option._id}
                    value={option._id}
                    disableRipple
                    disableTouchRipple
                  >
                    <Box className={classes.overflowText}>
                      <Typography  className={`${classes.truncatedTypography} ${option.name.length > 22 ? classes.overflown : ''}`}>
                        {option.name}
                      </Typography>
                    </Box>  
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled  sx={{ color: '#3C434D' }}>
                    No results were found!
                </MenuItem>
              )}
            </MuiSelect>
          </TitleTooltip>
        )}
      </>
    </FormControl>
  );
}

export function SelectActionButton({
  primaryAction=()=>{}, primaryLabel, primaryIcon, variant='contained', disabled=false,
  options, label = null, placeholder = 'Select option',
  loading = false, ...props
}) {
  const classes = useStyles();

  const [isDropdownOpen, setDropdownOpen] = React.useState(false);

  const menuProps = React.useMemo(() => ({
    classes: {
      paper: classes.paper,
      list: `${classes.groupList} ${classes.actionGroupList}`
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    MenuListProps: {
      style: {
        ...props.menuStyle
      }
    }
  }), []);

  const handleButtonClick = (event) => {
    primaryAction();
    event.stopPropagation();
  };
  
  const handleDropdownToggle = (event) => {
    setDropdownOpen((prev) => !prev);
    event.stopPropagation();
  };

  return (
    <FormControl>
      {
        label && <Typography className={classes.label}>{label}</Typography>
      }
      <>
        {
          (loading) ?
            <SelectSkeleton /> :
            <MuiSelect
              displayEmpty
              className={`
                ${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} 
                ${classes.actionSelect}
                `
              }
              sx={{ minWidth: 200, ...(props.sx ? props.sx : {}) }}
              renderValue={(selected) => {
                return(
                  <Button
                    className={classes.actionButton}
                    onClick={handleButtonClick}
                    startIcon={primaryIcon}
                  >
                    <Typography variant='h6-medium' color='primary.main'>
                      {primaryLabel}
                    </Typography>
                  </Button>
                ) 
              }}
              inputProps={{ className: classes.input }}
              MenuProps={menuProps}
              IconComponent={(iconProps) => (
                  <Box onClick={handleDropdownToggle} className={classes.actionDropdownIcon}>
                    <ExpandMoreIcon/>
                  </Box>
              )}
              open={isDropdownOpen}
              disabled={disabled}
              onClose={() => setDropdownOpen(false)}
              {...props}
              id="select"
            >
              {
                options?.map((option) => (
                  <MenuItem key={option._id} value={option._id} className={classes.actionMenuItems}>
                    <Box 
                      className={`${classes.actionMenuBtn} ${disabled && classes.actionMenuBtnDisabled}`} 
                      onClick={option?.onClick}
                    >
                      <img src={disabled? option.disabledIcon : option.icon} alt='action-icon' style={{ color: 'red'}}/>
                      <Typography variant='body01-medium' color={disabled && 'neutral.clr-500'}>
                        {option.name}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))
              }
            </MuiSelect>
        }
      </>
    </FormControl>
  );
};