import { styled } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
    label: {
        background: "#003E8C",
        height : '44px',
        width:'162px',
        borderRadius: 4,
        cursor: "pointer",
        padding: "0px 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 'fit-content',
        transition: "all 200ms",
        marginTop : '28px'
    }
});

const Input = styled('input')({ display: 'none' });

function ImageUploadButton(props) {
    const classes = useStyles();
    return (
        <label
            htmlFor="icon-button-file"
            className={classes.label}
            title="Upload Logo"
        >
            <Input
                accept="image/*"
                id="icon-button-file"
                type="file"
                onClick={(e) => e.target.value = null}
                {...props}
            />
            <img src="https://assets.languify.in/images/UploadLogo.svg" alt="upload"/>
            <Typography variant="body01-bold" color="#FFFFFF" ml={1}>
               Update Logo
            </Typography>
        </label >
    );
}

export default ImageUploadButton;