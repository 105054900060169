import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React from 'react'
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
  content: {
    height: '600px', padding: '0px', overflow: 'hidden'
  },
  closeBtn: {
    position: 'absolute',
    right: 8,
    top: 25,
    color: "#A7B1BE",
  },
  head: {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
  }
}));

const ScoringDialog = ({ openScoringDialog, setOpenScoringDialog }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(openScoringDialog);

  const handleClose = () => {
    setOpenScoringDialog(false);
  };

  React.useEffect(() => {
    setOpen(openScoringDialog);
  }, [openScoringDialog]);

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={'lg'}
      fullWidth={true}
      className={classes.root}
      open={open}
    >
      <Box className={classes.head}>
        <DialogTitle sx={{ m: 0, py: 4, px: 5 }} id="customized-dialog-title">
          Score Calculation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.closeBtn}
          variant='outlined'
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {/* content */}
      <DialogContent className={classes.content}>
        <iframe
          src='https://assets.languify.in/pdf/Languify+Scoring+doc.pdf#toolbar=0&navpanes=0&scrollbar=0'
          title="score"
          style={{ width: '100%', height: '100%', border: 'none' }}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ScoringDialog