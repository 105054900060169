import React from 'react';
import { useParams } from "react-router-dom";
import { Collapse } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import dayjs from 'dayjs';

import InviteUsers from 'dialogs/InviteUsers';
import { SendMail } from 'dialogs/Sendmail';
import { useSnackbar } from 'contexts';
import { ConfirmationDialog } from 'dialogs';
import { getBatchUserMappings, removeStudentsFromBatch } from 'services';
import { Session, formatDate, getMonths } from 'utils';
import StaticTable from 'components/Tables/StaticTable';
import { AccessControl, SubscriptionModel } from './constants';
import CustomButton, { IconButton } from 'components/CustomButtons';
import { useTheme } from '@mui/styles';
import Chip from 'components/Chip';
import theme from 'theme';
import { WhiteBox } from 'components/AppShell';
import { SelectActionButton } from 'components/CustomSelectFields';

const sx = {
  editBtn: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  addBackBtn: {
    fontSize: '12px',
    padding: '5px',
    color: '#fff',
    textTransform: 'capitalize',
    backgroundColor: '#07C782',
    '&:hover': {
      backgroundColor: '#07C782'
    }
  },
  viewLess: { border: '1px solid #02569D', padding: '3px 10px' },
  viewAll: { backgroundColor: '#02569D', color: '#fff'}
}

const columns = [
  { id: 'name', label: 'Name', field: 'name', minWidth: 100 },
  {
    id: 'onboardedAt', field: 'onboardedAt', type: 'date',
    label: 'Onboarded Date', minWidth: 100, align: 'center',
    renderCell: ({ row, field }) =>
      dayjs(row[field]).isValid() ? formatDate(row[field], 'DD-MMM-YYYY') : '-'
  },
  {
    id: 'remainingAccess', field: 'remainingAccess', label: 'Remaining Access', align: 'center',
    renderCell: ({ row, field }) => {
      return (
        <Box display='flex' justifyContent='center' gap='2px' alignItems='center'>
          {
            dayjs(row.accessExpiresOn).isBefore(dayjs(), 'day') < 0 ?
              <Chip
                content="Expired"
                size="small"
                bgColor={theme.palette.danger['clr-100']}
                sx={{ 
                  color: theme.palette.danger['clr-700'], 
                  padding : theme.spacing(0,2)
                }}
              />
              :
              <Typography variant='body01-semiBold'>
                {row[field]} Days
              </Typography>
          }
        </Box>
      )
    }
  },
  {
    id: 'accessExpiresOn', field: 'accessExpiresOn', type: 'date',
    label: 'Access Ending', align: 'center',
    renderCell: ({ row, field }) =>
      dayjs(row[field]).isValid() ? formatDate(row[field], 'DD-MMM-YYYY') : '-'
  },
];

const pastUserColumns = [
  { id: 'name', label: 'Name', field: 'name', minWidth: 100 },
  {
    id: 'onboardedAt', field: 'onboardedAt', type: 'date',
    label: 'Onboarded Date', minWidth: 100, align: 'center',
    renderCell: ({ row, field }) =>
      dayjs(row[field]).isValid() ? formatDate(row[field], 'DD-MMM-YYYY') : '-'
  },
  {
    id: 'accessExpiresOn', field: 'accessExpiresOn', type: 'date',
    label: 'Access Ending', align: 'center',
    renderCell: ({ row, field }) =>
      dayjs(row[field]).isValid()
        ? dayjs(row[field]).isBefore(dayjs())
          ?  
          <Box display='flex' justifyContent='center' gap='2px' alignItems='center'>
            <Chip
              content="Expired"
              size="small"
              bgColor={theme.palette.danger['clr-100']}
              sx={{ 
                color: theme.palette.danger['clr-700'], 
                padding : theme.spacing(0,2)
              }}
            />
            </Box>
        : formatDate(row[field], 'DD-MMM-YYYY')
        : '-'
  },
];

function StudentList({ onStudentCountChange, setActiveStudents }) {
  const { id } = useParams();
  const snackbar = useSnackbar();
  const theme = useTheme();
  const user = Session.user;

  const [loading, setLoading] = React.useState(false);
  const [inviteUsers, setInviteUsers] = React.useState(false);
  const [openMailDialog, setOpenMailDialog] = React.useState(false);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [removedRowSelectionModel, setRemovedRowSelectionModel] = React.useState([]);
  const [confirmRemove, setConfirmRemove] = React.useState(false);
  const [activeItemId, setActiveItemId] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [pastUserRows, setPastUserRows] = React.useState([]);
  const [pastUserCollapse, setPastUserCollapse] = React.useState(true);
  const [inviteUsersData, setInviteUsersData] = React.useState([]);
  const [failedEmailData, setFailedEmailData] = React.useState([]);
  const [addedUsers, setAddedUsers] = React.useState([]);
  const [userAction, setUserAction] = React.useState();
  const isPayPerUser = (user?.metadata?.subscriptionModel === SubscriptionModel.PAY_PER_USER);

  const loadUsers = async () => {
    let activeUsers = [];
    let pastUsers = [];
    try {
      setLoading(true);

      if (id && id !== "create") {
        const { batchUserMappings } = await getBatchUserMappings({
          batch: id, include: "user"
        });

        batchUserMappings?.map(bum => {
          const user = {
            _id: bum._id,
            name: bum?.user?.name || '-',
            email: bum?.user?.email || '',
            accessPeriod: getMonths(bum?.onboardedAt, bum?.accessExpiresOn) || 0,
            credits: getMonths(bum?.onboardedAt, bum?.accessExpiresOn) || 0,
            remainingAccess: dayjs(bum?.accessExpiresOn).diff(dayjs(), 'day') + 1 || 0,
            createdAt: bum.createdAt,
            onboardedAt: bum?.onboardedAt || '-',
            accessExpiresOn: bum?.accessExpiresOn || '-'
          }
          bum.removed || dayjs(bum.accessExpiresOn).isBefore(dayjs())
            ? pastUsers.push(user)
            : activeUsers.push(user)
        })
      }
    } catch (error) {
      console.log(error);
      snackbar.showSnackbar("Uh Oh! Unable to load users", "error");
    } finally {
      setLoading(false);
      setRows(activeUsers);
      setActiveStudents(activeUsers.length);
      setPastUserRows(pastUsers);
    }
  };

  const removeSelectedUsers = React.useCallback(async () => {
    const itemIds = rowSelectionModel;

    setRows(currRows => currRows.filter((v) => !itemIds.includes(v._id)));
    setConfirmRemove(false);

    try {
      await removeStudentsFromBatch(id, itemIds);
      snackbar.success("Successfully removed the selected students!!");
    } catch (error) {
      console.error(error);
      snackbar.error(error.message);
    } finally {
      loadUsers();
      setRowSelectionModel([]);
      setRemovedRowSelectionModel([]);
    }

  }, [rowSelectionModel]);

  const removeUser = React.useCallback(async () => {
    const itemId = activeItemId;
    setLoading(true);

    try {
      await removeStudentsFromBatch(id, [itemId]);
      snackbar.success("Successfully removed the student!!");

    } catch (error) {
      console.error(error);
      snackbar.error(error.message);
    } finally {
      loadUsers();
      setActiveItemId(false);
      setRowSelectionModel([]);
      setRemovedRowSelectionModel([]);
      setLoading(false);
      setRows(currRows => currRows.filter((v) => itemId !== v._id));
    }
  }, [activeItemId]);

  const handleAddBack = ({ addAll = false }) => {
    let users = []
    pastUserRows.forEach((user) => {
      if (addAll) {
        users.push({ ...user, previousExpiry: user.accessExpiresOn, credits: 0 })
      }
      else if (removedRowSelectionModel.includes(user._id)) {
        users.push({ ...user, previousExpiry: user.accessExpiresOn, credits: 0 })
      }
    })
    setInviteUsersData(users);
    setUserAction(AccessControl.RESUME);
    setInviteUsers(true);
  }

  const handleUpgrade = () => {
    if(!rowSelectionModel.length){
      snackbar.warn("Please select at least one user to upgrade");
      return;
    }
    let users = []
    rows.forEach((user) => {
      if (rowSelectionModel.includes(user._id)) {
        users.push({ ...user, previousExpiry: user.accessExpiresOn, credits: 0 })
      }
    })
    setInviteUsersData(users);
    setUserAction(AccessControl.UPGRADE);
    setInviteUsers(true);
  }

  const LiveUserActions = [
    {
      title: "Remove",
      renderIcon: () => <IconButton 
        variant='outlined'
        style={{
                  border : `1px solid ${theme.palette.primary.main}`,
                  padding : theme.spacing(1)
              }}
        >
          <img 
            src='https://assets.languify.in/images/remove-student-icon.svg' 
            alt='remove' 
          />
        </IconButton>,
      label: "Remove Student",
      onClick: (param) => {
        setActiveItemId(param.id);
      }
    }
  ]

  const pastUserActions = [
    {
      title: "Add Back",
      renderIcon: () => <IconButton 
        variant='contained'
        style={{
                  border : `1px solid ${theme.palette.primary.main}`,
                  padding : theme.spacing(1)
              }}
        >
          <img 
            src='https://assets.languify.in/images/add-student.svg' 
            alt='add' 
          />
        </IconButton>,
      label: "Add Back",
      onClick: (param) => {
        let pastData = pastUserRows.filter((user) => user._id === param.id)
        setInviteUsersData([{ ...pastData[0], previousExpiry: pastData[0].accessExpiresOn, credits: 0 }]);
        setUserAction(AccessControl.RESUME);
        setInviteUsers(true);
      }
    }
  ]

  const handleInviteUsersClose = (result) => {
    setInviteUsers(false);
    if (result) loadUsers();
  };

  React.useEffect(() => (id !== "create") && loadUsers(), [id]);

  React.useEffect(() => {
    if (typeof onStudentCountChange === 'function')
      onStudentCountChange(rows.length);
  }, [rows]);

  React.useEffect(() => {
    if (typeof getItems === 'function') {
      loadUsers()
        .then((result) => setRows(result))
        .catch((error) => snackbar.error(error.message))
    }
  }, []);

  React.useEffect(() => {
    if (failedEmailData.length || addedUsers.length) {
      setOpenMailDialog(true);
    }
  }, [failedEmailData, addedUsers]);

  const handleCollapse = () => {
    setPastUserCollapse(!pastUserCollapse)
  }

  const actionButtonOptions = [
    {
      _id: 1, name: 'Resend credentials', 
      icon: 'https://assets.languify.in/images/send-creds-icon.svg',
      disabledIcon: 'https://assets.languify.in/images/send-cred-diabled.svg',
      onClick: () => {
        if(!rowSelectionModel.length){
          snackbar.warn("Please select at least one user to send credentials");
          return;
        }
        const selectedUsers = rows
        .filter((student) => rowSelectionModel.includes(student._id))
        .map((student) => ({ name: student.name, email: student.email })); 
        
        setAddedUsers(selectedUsers);
        setOpenMailDialog(true);
      }
    }, 
    {
      _id: 2, name: 'Remove students', 
      icon: 'https://assets.languify.in/images/remove-student-icon.svg',
      disabledIcon: 'https://assets.languify.in/images/remove-stu-disabled.svg',
      onClick: () => {
        if(!rowSelectionModel.length){
          snackbar.warn("Please select at least one user to remove students");
          return;
        }
        setConfirmRemove(true);
      }
    }
  ]

  return (
    <div>
      <WhiteBox>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box mb={4}>
            <Typography variant="h4-medium">Live Students</Typography><br/>
            <Typography variant="h6-medium" color="neutral.clr-600">
              Add, remove & view students of this batch
            </Typography>
          </Box>
          <Box display='flex' gap='4px'>
            {/* {(rowSelectionModel.length > 0) && (
              <CustomButton
                size='medium'
                onClick={() => handleUpgrade()}
              >
                {
                  isPayPerUser ? <>Update Subscription</> : <>Update Access</>
                }
              </CustomButton>
            )} */}
              {/* <CustomButton
                size='medium'
                onClick={() => {
                  setConfirmRemove(true);
                }}
                sx={{ border: '1px solid #02569D' }}
              >
                Remove Selected
              </CustomButton> */}

              <CustomButton
                startIcon={<AddIcon />}
                size='medium'
                onClick={() => {
                  setUserAction(AccessControl.GRANT);
                  setInviteUsersData([]);
                  setInviteUsers(true);
                }}
              >
                Add new students
              </CustomButton>
              <SelectActionButton
                options={actionButtonOptions}
                primaryAction={()=> handleUpgrade()}
                primaryLabel={'Update Students Access'}
                primaryIcon={
                  <img src={!rowSelectionModel.length 
                    ? 'https://assets.languify.in/images/calendar-plus-disabled.svg' 
                    : 'https://assets.languify.in/images/calendar-plus.svg'} alt='cal'
                  />
                }
                variant='outlined'
                sx={{width: '310px'}}
                disabled={!rowSelectionModel.length}
              />
          </Box>
        </Box>
        <Box>
          <StaticTable
            loading={loading}
            height="300px"
            noRowsMessage="No active students in this batch!!"
            rows={rows}
            actions={LiveUserActions}
            columns={columns}
            showRowSelectCheckbox={true}
            setRowSelectionModel={setRowSelectionModel}
            rowSelectionModel={rowSelectionModel}
            showColumnSelection={false}
            showExports={false}
          />
        </Box>
      </WhiteBox>

      {/* past Users */}
      <WhiteBox
        mt='16px' p='7px'
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding={'12px 0px'}
        >
          <Box>
            <Typography variant="h4-medium">Past Users</Typography><br/>
            <Typography mb={1} variant="h6-medium" color="neutral.clr-600">
              Click to view and activate the students back in the Batch.
            </Typography>
          </Box>
          <Box>
            <CustomButton
              size='small'
              sx={pastUserCollapse ? sx.viewAll : sx.viewLess}
              onClick={handleCollapse}
              variant= {
                pastUserCollapse ?
                  'contained' : 'outlined'
              }
            >
              {
                pastUserCollapse ?
                  'View' : 'View Less'
              }
            </CustomButton>
          </Box>
        </Box>
        <Collapse in={pastUserCollapse !== true}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt='10px'
          >
            <Box>
              <Typography variant="body01-bold">Discontinued Enrolees</Typography><br/>
              <Typography mb={1} variant="body02-semiBold" color='neutral.clr-800'>
                View Past users & add them back.
              </Typography>
            </Box>
            <Box display='flex' gap='4px'>
              {removedRowSelectionModel.length > 0 && (
                <CustomButton
                  size='medium'
                  startIcon={<PersonAddAlt1OutlinedIcon />}
                  onClick={() => handleAddBack({ addAll: false })}
                  sx={{ fontSize: '12px' }}
                  variant='outlined'
                >
                  Add Back Selected
                </CustomButton>
              )}
              <CustomButton
                startIcon={<AddIcon />}
                onClick={() => handleAddBack({ addAll: true })}
                size='medium'
                sx={{ fontSize: '12px' }}
                disabled={!pastUserRows.length}
              >
                Add Back All
              </CustomButton>
            </Box>
          </Box>
          <Box backgroundColor='#fff'>
            <StaticTable
              height="300px"
              noRowsMessage="No Past Users in this batch!!"
              rows={pastUserRows}
              actions={pastUserActions}
              columns={pastUserColumns}
              showRowSelectCheckbox={true}
              setRowSelectionModel={setRemovedRowSelectionModel}
              rowSelectionModel={removedRowSelectionModel}
              showColumnSelection={false}
              showExports={false}
            />
          </Box>
        </Collapse>
      </WhiteBox>
      <InviteUsers
        open={inviteUsers} onClose={handleInviteUsersClose} batchId={id}
        inviteUsersData={inviteUsersData} setInviteUsersData={setInviteUsersData}
        action={userAction}
        isPayPerUser={isPayPerUser}
        setAddedUsers={setAddedUsers}
      />
      <SendMail
        open={openMailDialog} setOpen={setOpenMailDialog}
        onClose={() => setOpenMailDialog(false)}
        failedEmailData={failedEmailData}
        setFailedEmailData={setFailedEmailData}
        inviteUsersData={inviteUsersData}
        users={addedUsers}
        setUsers={setAddedUsers}
      />
      <ConfirmationDialog
        open={!!activeItemId}
        onPrimaryClick={removeUser}
        onSecondaryClick={() => setActiveItemId(false)}
        primaryActionVariant="error"
        primaryAction='Confirm!'
        secondaryAction='No'
        multipleMessage={[
          <Typography variant='h5-medium'>Are your sure you want to remove the student?</Typography>,
          <Typography variant='h6-regular'>By removing the user, they would no longer be able to take any attempts in this Batch</Typography>
        ]}
        title='Confirm User Removal'
      />
      <ConfirmationDialog
        open={confirmRemove}
        onPrimaryClick={removeSelectedUsers}
        onSecondaryClick={() => setConfirmRemove(false)}
        primaryActionVariant="error"
        primaryAction='Confirm'
        secondaryAction='No'
        multipleMessage={[
          <Typography variant='h5-medium'>
            Are your sure you want to remove {rowSelectionModel.length} student(s)?
          </Typography>,
          <Typography variant='h6-regular'>By removing the user, they would no longer be able to take any attempts in this Batch</Typography>
        ]}
        title='Confirm User Removal'
      />
    </div>
  );
}

export default StudentList;