import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import CustomLink from "components/CustomLink";
import { WhiteBox } from "./AppShell";
import { useTheme } from "@emotion/react";
import TitleTooltip from "./TitleTooltip";

const useStyles = makeStyles(theme=>({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "auto",
    overflow: "hidden",
    padding: theme.spacing(7,10)
  },
  seeMore: {
    fontWeight: "500",
    fontSize: "15px",
    zIndex: "100",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    position: "absolute",
    bottom: "10px",
    right: "30px",
    transition: "background-color 0.3s ease-out 200ms",
  },
  createContainer:{
    display : 'flex',
    justifyContent :'center',
    alignItems:'center',
    flexDirection:'column',
    border : `3px solid ${theme.palette.primary.main}`,
    borderRadius : theme.spacing(2),
    backgroundColor : theme.palette.shades['clr-900'],
    "&:hover": {
      backgroundColor : theme.palette.neutral['clr-50'],
      boxShadow: '0px 0px 8px 0px #1961BF4D'
    }
  },
  cardContainer: {
    position: "relative",
    display: "flex",
    gap:theme.spacing(2),
    justifyContent:'center',
    flexDirection: 'column',
    padding: theme.spacing(3,5),
    minWidth: "450px",
    maxWidth: "450px",
    height: "196px",
    maxHeight: "196px",
    background: "#FFFFFF",
    border : `3px solid ${theme.palette.neutral['clr-300']}`,
    borderRadius : theme.spacing(2),
    cursor: "pointer",
    transition: "all 400ms ease-in-out",
    "&:hover": {
      borderColor : theme.palette.primary['clr-100'],
      boxShadow: '0px 0px 8px 0px #1961BF4D'
    }
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "20px",
    letterSpacing: "0",
    margin: "2px 0px",
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  content: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#535353",
  },
  tag: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    backgroundColor: theme.palette.success['clr-100'],
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    color: theme.palette.success['clr-700'],
    width: "fit-content",
    height: "fit-content",
    padding: theme.spacing(1,3),
    marginRight: 5
  },
  expire: {
    color: "#E52151",
    background: "#EFD5DA"
  },
  editButton: {
    position: "absolute",
    right: 8,
    bottom: 8,
    zIndex: 1,
  },
  emptyCard: {
    padding: 32, alignItems: "center",
    justifyContent: "center", display: "flex",
    flexDirection: "column",gap: theme.spacing(5.5),
    textDecoration: 'none', color: '#02569D',
  },
  addIcon:{
    border : `3px solid ${theme.palette.primary.main}`,
    borderRadius : '50%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'60px',
    height:'60px'
  },
  cardTitle: {
    textTransform: 'capitalize',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: '365px',
    marginBottom:'4px'
  },
}));

export function Tag({ name, expire = false }) {
  const classes = useStyles();
  const tagClasses = classNames({
    [classes.tag]: true,
    [classes.expire]: expire,
  })
  return (<Box className={tagClasses}>
    <Typography variant="body01-bold">{name}</Typography>
  </Box>);
}


export function Card({ item, title }) {
  const classes = useStyles();

  return (
    <Grid
      item
      className={classes.cardContainer}
      onClick={item?.onClick}
      title={title}
    >
      {item?.actions?.map(({ Icon, ...action }, index) => action?.hide ? null : (
        <IconButton
          key={index}
          className={classes.editButton}
          color={action?.color || "primary"}
          onClick={action?.onClick}
        >
          <Icon />
        </IconButton>
      ))}

      <Box display={"flex"} flexWrap="wrap" alignItems="start">
        {item?.tags?.map(({ name, expire }, index) => (
          <Tag name={name} expire={expire} key={index} />
        ))}
      </Box>    
        <Box
          className={classes.cardContent}
          justifyContent="flex-end"
          flexGrow={1}
          pl={'4px'}
          pb={0}
        >
          {item?.type && (<Typography variant="body01-bold" color='neutral.clr-500'>
            {item.type}
          </Typography>)}
          <TitleTooltip 
            placement='bottom'
            component={item?.name}
            disableHoverListener={item?.name < 20}
            positionStyle={{ top: '-110px !important', left : '50px !important' , zIndex:2 }}
            onClick={item?.onClick}
            sx={{  maxWidth: 320 }}
          >
             <Box sx={{ display: 'flex' }}>
                <Typography className={classes.cardTitle} variant='h4-semiBold' color='neutral.clr-700'>
                  {item?.name || ""}
                </Typography>
            </Box>
          </TitleTooltip>
          {item?.infos?.length > 0 && (
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
            >
              {item?.infos.map((info, index) => (
                <Typography
                  variant="body01-semiBold"
                  color='neutral.clr-900'
                  key={index}
                  style={{ display: "inline-flex", alignItems: "center" }}
                >
                  {info}&nbsp;
                </Typography>
              ))}
            </Box>
          )}
        </Box>
    </Grid>
  );
}

export function CardList({
  items = [], title, subTitle , newPath = '', seeMorePath = '', cardTitle = ''
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <WhiteBox my={5}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={0}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
          <Typography
            variant="h4-medium"
            color='neutral.clr-900'
            mr={2}
            mb={1}
          >
            {title}
          </Typography>
          <Typography
            variant="h6-medium"
            color='neutral.clr-600'
            mr={2}
            mb={1}
          >
            {subTitle}
          </Typography>
        </Box>
        <CustomLink to={seeMorePath} 
        style={{ 
          textDecoration: 'none', 
          color: '#02569D' , 
          display:'flex' , 
          gap:'4px' , 
          border : '1px solid #003E8C', 
          padding : theme.spacing(2,3),
          borderRadius : '4px'
          }}
        >
          <Typography variant="body01-bold" color='primary.main'>See all</Typography>
          <img src="https://assets.languify.in/images/arrow-right-icon.svg"  alt="arrow"/>
        </CustomLink>
      </Box>
      <Box height={'auto'} maxHeight={228} overflow="hidden" paddingTop={7}>
        <Grid container gap={6} justifyContent="flex-start">
          <Grid
            item
            className={classes.createContainer}
          >
            <CustomLink to={newPath} className={classes.emptyCard} >
              <Box className={classes.addIcon}>
                <AddIcon fontSize="large" color="primary" />
              </Box>
              <Typography
                textAlign={"center"}
                variant="h5-semiBold"
                color='primary.main'
              >
                Create new
              </Typography>
            </CustomLink>
          </Grid>
          {items.map((item, index) => (
            <Card key={index} item={item} title={cardTitle} />
          ))}
        </Grid>
      </Box>
    </WhiteBox>
  );
}

export default CardList;