import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import { IconButton } from 'components/CustomButtons';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { deleteUserById, getBatches, getStudents, updateProfileStatus } from 'services';
import useEditorStore from 'editors/TemplateEditor/editorStore';
import { sendBulkInviteMails } from 'services/inviteEmail';
import { TourSteps, useSnackbar, useTour } from 'contexts';
import { Session, applyFiltersByHistory } from 'utils';
import { ConfirmationDialog } from 'dialogs';
import { WhiteBox } from 'components/AppShell';
import ScreenHeading from 'components/ScreenHeading';
import DynamicTable from 'components/DynamicTable';
import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import Chip from 'components/Chip';

dayjs.extend(advancedFormat);

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 24, height: 24, fontSize: 12,
    },
    children: `${name.charAt(0)}`,
  };
}


function Students(props) {
  const { state: tourState } = useTour();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const theme = useTheme();

  const [filterOptions, setFilterOptions] = React.useState({});
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const pageFilters = useEditorStore((state) => state.pageFilters);
  const setPageFilters = useEditorStore((state) => state.setPageFilters);
  const pageHistory = Session.pageHistory;
  const [confirmRemove, setConfirmRemove] = React.useState(false);
  const [activeItemId, setActiveItemId] = React.useState(false);
  const [refreshData, setRefreshData] = React.useState(false);

  const [initialFilters, setInitialFilters] = React.useState({
    client: Session.userId,
    includeTotal: true,
    roleNames: 'STUDENT',
    order: 'desc', orderBy: 'metadata.lastActive',
    deactivated: false
  });

  useEffect(() => {
    if (rowSelectionModel && rowSelectionModel.length > 0) {
      navigate(`/students/${rowSelectionModel[0]}`);
    }
  }, [rowSelectionModel]);

  const columns = [
    {
      id: 'name', label: 'Name', minWidth: 80, maxWidth: 150,
      renderCell: ({ row }) => {
        return (
          <Box display='flex' alignItems='center' gap='5px'>
            <Avatar
              src={row} {...stringAvatar(row?.name || row?.email)}
              style={{ height: '22px', width: '22px' }}
            />
            <Typography fontSize='14px'>
              {row?.name}
            </Typography>
          </Box>
        )
      }
    },
    { id: 'email', label: 'Email', align: 'center', minWidth: 60, maxWidth: 150 },
    {
      id: 'metadata.lastActive', label: 'Last Active', flex: 0, width: 190,
      align: 'center', type: 'date',
      renderCell: ({ row }) =>
        dayjs(row?.metadata?.lastActive || row?.createdAt).format("hh:mma (Do,MMM YYYY)"),
      sort: false,
    },
    {
      id: 'metadata.accessExpiresOn', label: 'Remaining Access', flex: 0, width: 190, align: 'center',
      renderCell: ({ row }) => {
        return (
          <Box display='flex' justifyContent='center' gap='2px' alignItems='center'>
            {
              dayjs(row.metadata?.accessExpiresOn).isBefore(dayjs()) ?
                  <Chip
                    content="Expired"
                    size="small"
                    bgColor={theme.palette.danger['clr-100']}
                    sx={{ 
                      color: theme.palette.danger['clr-700'], 
                      padding : theme.spacing(0,2)
                    }}
                  />
                :
                <Typography variant='body01-semiBold'>
                  {dayjs(row.metadata?.accessExpiresOn).diff(dayjs(), 'day') || 0} Days
                </Typography>
            }
          </Box>
        )
      }
    },
    {
      id: "analytics",
      label: "Analytics", fixed: true,
      minWidth: 80, disableSorting: true,
      align: "center",
      renderCell: ({ row }) =>
         <IconButton  onClick={() => navigate(`/students/${row._id}`)}  variant='contained'
         style={{
                   border : `1px solid ${theme.palette.primary.main}`,
                   padding : theme.spacing(1),
                   marginLeft : theme.spacing(18)
               }}>
                    <img 
                       src='https://assets.languify.in/images/PerformanceIcon.svg' 
                       alt='performance' 
                     />
       </IconButton>,
    },
  ];

  const filters = [
    { field: 'email', type: 'text', label: 'Email' },
    { field: 'batch', type: 'select', label: 'Batch' },
  ];

  const handleDeactivateUser = async (e) => {
    try {
      await updateProfileStatus(activeItemId, 'Deactive');
      setConfirmRemove(false);
      setActiveItemId(false);
      setRefreshData(true);
    }
    catch (error) { console.error(error) }
    finally { setRefreshData(false) }
  };

  React.useEffect(() => {
    applyFiltersByHistory(pageFilters, setPageFilters, setInitialFilters);
  }, [pageHistory])

  React.useEffect(() => {
    getBatches({ client: Session.userId }).then(({ batches }) => {
      setFilterOptions((fO) => ({
        ...fO, batch: batches.map(b => ({ id: b._id, label: b.name }))
      }));
    }).catch(console.error);
  }, []);

  const sendInviteEmail = async (students = []) => {
    try {
      const result = await sendBulkInviteMails(students);
      if(result?.successful?.length){
        snackbar.success('Successfully sent invite email', true);
      }else{
        snackbar.error('Unable to send invite email', true);
      }
    } catch (error) {
      console.error(error);
      snackbar.error('Unable to send invite email', true);
    }
  }

  return (
    <Box display="flex" flexDirection="column" minHeight='calc(100vh - 56px)' pb={6}>
      <ScreenHeading title="Active Students" id="students-tip">
      </ScreenHeading>
      <WhiteBox >
        <DynamicTable
          hideDeleteAction
          serverMode
          refreshData={refreshData}
          noRowsMessage="No Students Found"
          showDummyRows={tourState}
          dummyRows={TourSteps[4].data.students}
          entity="User"
          columns={columns}
          filters={filters}
          filterOptions={filterOptions}
          menuOptions={[
            {
              label: 'Deactivate Student',
              onClick: (event, params) => {
                event.stopPropagation(); 
                setActiveItemId(params?.row?._id);
                setConfirmRemove(true);
              }
            },
            {
              label: 'Send Invite Email',
              onClick: (event, params) => {
                event.stopPropagation();
                setActiveItemId(params?.row?._id);
                sendInviteEmail([{
                  name: params?.row?.name,
                  email: params?.row?.email
                }]);
              }
            },
          ]}
          useListProps={{
            getItems: getStudents,
            deleteItemById: deleteUserById,
            itemName: 'users',
            initialFilters: initialFilters
          }}
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setRowSelectionModel}
        />
      </WhiteBox>
      <ConfirmationDialog
        message={'Are you sure, you want to deactivate user'}
        open={confirmRemove}
        title={"Deactivate User"}
        primaryActionVariant="primary"
        primaryAction={"Deactivate"}
        secondaryAction="Cancel"
        onPrimaryClick={handleDeactivateUser}
        onSecondaryClick={() => setConfirmRemove(false)}
      />
    </Box>
  );
}

export default Students;