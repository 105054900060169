export const SUB_CATEGORIES = {
    "FORMALITY": "FORMALITY",
    "CONFIDENCE": "CONFIDENCE",
    "PAUSES": "PAUSES",
    "DISFLUENCY": "DISFLUENCY",
    "PACE": "PACE",
    "ENERGY": "ENERGY",
    "GREETING": "GREETING",
    "CONCLUSION": "CONCLUSION",
    "COMPELLING_VOCAB": "COMPELLING_VOCAB",
    "ACTION_VERBS": "ACTION_VERBS",
    "JARGON_WORDS": "JARGON_WORDS",
    "VOCAB_RANGE": "VOCAB_RANGE",
    "HINDI_SHARE": "HINDI_SHARE",
    "TALK_SHARE": "TALK_SHARE",
    "INFORMAL_WORDS": "INFORMAL_WORDS",
    "INCLUSIVE_WORDS": "INCLUSIVE_WORDS",
    "OFFENSIVE_WORDS": "OFFENSIVE_WORDS",
    "FILLER_WORDS": "FILLER_WORDS",
    "FILLER_PHRASES": "FILLER_PHRASES",
    "RARE_USED_WORDS": "RARE_USED_WORDS"
};

export const between = (min, max, value) => {
    return value >= min && value <= max;
};

const parsePercentageString = (
    prefix,
    value,
    suffix = ""
) => {
    if (value === 0) return null;
    return `${prefix}${value}${suffix}`;
}

export const ParsingLookup = {
    [SUB_CATEGORIES.FILLER_WORDS]: (feature) => {
        return feature.value;
    },
    [SUB_CATEGORIES.FILLER_PHRASES]: (feature) => {
        return feature.value;
    },
    [SUB_CATEGORIES.COMPELLING_VOCAB]: (feature) => {
        if (!Boolean(feature.value)) return "Try using compelling words";
        return null;
    },
    [SUB_CATEGORIES.CONCLUSION]: (feature) => {
        if (!Boolean(feature.value)) return "Missed out on concluding remarks";
        return null;
    },
    [SUB_CATEGORIES.GREETING]: (feature) => {
        if (!Boolean(feature.value)) return "Missed out on greeting others";
    },
    [SUB_CATEGORIES.CONFIDENCE]: (feature) => {
        if (between(71, 100, feature.value))
            return 'Sounds Confident overall!';
        if (between(41, 70, feature.value))
            return 'Sounds Underconfident in some parts';
        if (between(0, 40, feature.value))
            return 'Need to work on confidence';
        return null;
    },
    [SUB_CATEGORIES.PAUSES]: (feature) => {
        if (between(0, 70, feature.value))
            return 'Too many extra Pauses';
        return null;
    },
    [SUB_CATEGORIES.FORMALITY]: (feature) => {
        if (between(71, 100, feature.value))
            return 'Sounds Formal';
        if (between(41, 70, feature.value))
            return 'A bit informal in some parts';
        if (between(0, 40, feature.value))
            return 'Quite informal speech';
        return null;
    },
    [SUB_CATEGORIES.PACE]: (feature) => {
        if (feature.value > 160)
            return 'Speaking too fast!';
        if (between(101, 160, feature.value))
            return 'Speaking pace: Perfect!';
        if (between(71, 100, feature.value))
            return 'Speaking Slow!';
        if (between(0, 70, feature.value))
            return 'Speaking pace: Too Slow!';
        return null;
    },
    [SUB_CATEGORIES.ENERGY]: (feature) => {
        if (between(1.1, 2.0, feature.value))
            return 'Speaking energy: Perfect';
        if (between(0.0, 1.0, feature.value))
            return 'Speaking energy: Too Dull';
        return null;
    },
    [SUB_CATEGORIES.INCLUSIVE_WORDS]: (feature) => {
        return feature.value;
    },
    [SUB_CATEGORIES.INFORMAL_WORDS]: (feature) => {
        return feature.value;
    },
    [SUB_CATEGORIES.OFFENSIVE_WORDS]: (feature) => {
        return feature.value;
    },
    [SUB_CATEGORIES.HINDI_SHARE]: (feature) => {
        if (feature.value)
            return parsePercentageString("", feature.value, "%");
        else return null;
    },
    [SUB_CATEGORIES.RARE_USED_WORDS]: (feature) => {
        if (feature.normPercentage) {
            const formattedPercentage = parseFloat(feature.normPercentage).toFixed(2);
            return parsePercentageString("", formattedPercentage, "%");
        } else {
            return null;
        }
    },    
    [SUB_CATEGORIES.DISFLUENCY]: (feature) => {
        return parsePercentageString("", feature.value, " time(s)");
    },
    [SUB_CATEGORIES.TALK_SHARE]: (feature) => {
        return parsePercentageString("", feature.value, "%");
    },
    [SUB_CATEGORIES.VOCAB_RANGE]: (feature) => {
        return parsePercentageString("", feature.value, "%");
    },
    [SUB_CATEGORIES.ACTION_VERBS]: (feature) => {
        return "Action verbs absent";
    },
    [SUB_CATEGORIES.JARGON_WORDS]: (feature) => {
        return "Jargon words absent";
    }
};
