import React, { useState } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

import ActionButtons from "components/ActionButtons";
import CustomButton from "components/CustomButtons";
import ScoringDialog from "dialogs/ScoringDialog";
import { overwriteClientById } from "services";
import { useSnackbar } from "contexts";
import { Session } from "utils";
import CenterFlexBox from "components/CenterFlexBox";


const useStyle = makeStyles(theme => ({
    container: {
        display: 'flex', justifyContent: 'space-around'
    },
    leftContainer: { width: '40%' },
    rightContainer: {
        width: '40%', display: 'flex',
        flexDirection: 'column', gap: theme.spacing(3), justifyContent: 'center', alignItems: 'center'
    },
    title: {
        fontFamily: 'inter', fontWeight: 600, fontSize: 18, marginBottom: 4,
    },
    content: {
        fontFamily: 'inter', fontWeight: 500, fontSize: 14,
    },
    divider: {
        border: '0.5px solid #D9D9D9',
    },
    percent: {
        ...theme.typography['body01-medium'],
        color: theme.palette.primary.main,
        border: '1px solid',
        borderColor: theme.palette.neutral['clr-200'],
        padding: 5, borderRadius: 2,
    },
    content: {
        padding: theme.spacing(2, 0),
        overflowY: 'hidden',
        width: '100%',
        overflow: 'hidden',
    },
    doc: {
        overflowY: 'hidden',
        width: '100%',
        overflow: 'hidden',
        display: 'flex', flexDirection: 'column',
        justifyContent: 'space-between',
        gap: theme.spacing(3),
        width: '360px'
    },
}));

export default function Scoring() {
    const classes = useStyle();
    const snackbar = useSnackbar();
    const [weightage, setWeightage] = React.useState({
        communication: Session?.user?.metadata?.performanceWeightage?.communication || 50,
        content: Session?.user?.metadata?.performanceWeightage?.content || 50,
    });

    const [loading, setLoading] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [openScoringDialog, setOpenScoringDialog] = useState(false)

    const handleChange = (e, v) => {
        setEdit(true);
        setWeightage({ communication: 100 - v, content: v });
    }

    const handleSave = async () => {
        try {
            setLoading(true);

            const client = {
                metadata: { performanceWeightage: weightage }
            };

            const newClient = await overwriteClientById(Session.userId, client);

            Session._setUser(newClient);
            setEdit(false);

            snackbar.showSnackbar('Successfully saved', 'success');
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error');
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.leftContainer}>
                <Slider
                    aria-label="Temperature"
                    defaultValue={Session?.user?.metadata?.performanceWeightage?.content || 50}
                    step={10} min={0} max={100}
                    onChangeCommitted={handleChange}
                />

                <CenterFlexBox justifyContent='space-between'>
                    <Typography variant='body01-bold'> Speech </Typography>
                    <Typography variant='body01-bold'> Content </Typography>
                </CenterFlexBox> <br />

                <CenterFlexBox justifyContent='space-between'>
                    <Box className={classes.percent}> {weightage.content}% </Box>
                    <Box className={classes.percent}> {weightage.communication}% </Box>
                </CenterFlexBox> <br /><br />

                <Typography variant="body01-medium" color='neutral.clr-800'>
                    <b>Adjust</b> the weightage of <b>Speech</b> and <b>content</b> &nbsp;
                    scores to calculate your overall score as per your preference.
                    This allows you to focus on the aspect that is more relevant to your goals.
                </Typography> <br /><br />

                {(edit) && <ActionButtons
                    loading={loading}
                    primaryFirst
                    primaryAction="Save"
                    secondaryAction="Cancel"
                    primaryActionVariant="primary"
                    secondaryActionVariant="secondary"
                    onSecondaryClick={() => setEdit(false)}
                    onPrimaryClick={handleSave}
                />}
            </Box>

            <Box className={classes.divider}></Box>

            <Box className={classes.rightContainer} >
                <Box mb={2}>
                    <Typography variant="h4-medium">
                        Know how the Scores are calculated :
                    </Typography><br />
                </Box>
                <Box className={classes.doc} id='module-intro'>
                    <Box width='360px' height='203px'>
                        <img
                            style={{ height: '100%', width: '100%' }}
                            src='https://assets.languify.in/images/scoring.png'
                            alt='thumbnail'
                        />
                    </Box>
                    <CustomButton onClick={() => setOpenScoringDialog(true)} size='large' variant='outlined'>
                        Read Now
                    </CustomButton>
                </Box>
            </Box>
            <ScoringDialog openScoringDialog={openScoringDialog} setOpenScoringDialog={setOpenScoringDialog} />
        </Box>
    );
}