import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { getBatches, getQuickHiglights } from "services";
import { Session } from "utils";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SimpleSelect } from "components/CustomSelectFields";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: 150,
        gap:theme.spacing(7),
        paddingTop: theme.spacing(5)
    },
    card: {
        display: "flex",
        justifyContent:'space-between',
        height: "124px",
        width:'248px',
        borderRadius: theme.spacing(2),
        padding:theme.spacing(5),
        boxShadow: '0px 0px 3px 0px #0000004D',
        backgroundColor : theme.palette.shades['clr-white-900'],
        marginLeft:theme.spacing(0.1)
    },
    cardBody: {
        display: "flex",
        flexDirection: "column",
        gap:theme.spacing(1)
    },
    cardTitle: {
        fontWeight: "500",
        textTransform: "capitalize",
    },
    cardImageContainer: {
        height: "64px",
        width:'64px',
        aspectRatio: "1",
    },
    cardImage: {
        height: "100%",
        objectFit: "cover",
        position:'relative',
        top:'8px'
    },
    unlockCard: {
        display: 'flex',
        gap: theme.spacing(5),
        flexDirection: "row",
        background: theme.palette.warning['clr-100'],
        padding: theme.spacing(6,5),
        margin: theme.spacing(6,0),
        color: theme.palette.shades['clr-black-900'],
        borderRadius: theme.spacing(2),
        boxShadow: "0px 0px 4px 0px #00000026"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        rowGap: 12,
    },
    batchHighlights:{
        display:'flex',
        justifyContent:'space-between',
        zIndex:2
    },
    batchHeading:{
        display:'flex',
        flexDirection:'column',
        gap:theme.spacing(1),
        zIndex:2
    },
    sparkles:{
        marginLeft:'4px',
    }
}));

const QuickHighlightDetails = {
    'TOPPER': { icon: 'https://assets.languify.in/gifs/rabbit.gif'},
    'MOST_DEDICATED': { icon: 'https://assets.languify.in/gifs/unicorn.gif'},
    'TAIL_ENDER': { icon: 'https://assets.languify.in/gifs/snail.gif' }
}

function ActiveAssessmentCard({ primary, secondary}) {
    const classes = useStyles()
    return (
        <Box  className={classes.card}>
            <Box className={classes.cardBody}>
                <Typography variant="h6-semiBold" color='neutral.clr-500'>{secondary}</Typography>
                <Typography variant="h2-semiBold"  color='neutral.clr-800'>
                    {primary.toString().padStart(2, '0')}
                </Typography>
            </Box>
        </Box>
    );
}
function HighlightsCard({ primary, secondary, value, imageSrc }) {
    const classes = useStyles()
    return (
        <Box  className={classes.card}>
            <Box className={classes.cardBody}>
                <Typography variant="h6-semiBold" color='primary.clr-300'>{secondary}</Typography>
                <Typography variant="h6-semiBold"  color='neutral.clr-800'>
                    {primary}
                </Typography>
                <Typography variant="body01-semiBold"  color='neutral.clr-800'>
                    {value}
                </Typography>
            </Box>
            <Box className={classes.cardImageContainer}>
                <img src={imageSrc} alt="" className={classes.cardImage} />
            </Box>
        </Box>
    );
}

function CardCarasoul({ items }) {
    const { container } = useStyles();
    return (
           <Box className={container}>
                {
                    items.map((item) => (
                        item.id === 'ACTIVE_ASSESSMENT' ? (
                            <ActiveAssessmentCard
                                key={item.id}
                                primary={item.title}
                                secondary={item.subtitle}
                                imageSrc={item.icon}
                            />
                        ) : (
                            <HighlightsCard
                                key={item.id}
                                primary={item.title}
                                secondary={item.subtitle}
                                value={item.value}
                                imageSrc={item.icon}
                            />
                        )
                    ))
                }
           </Box>
    );
}


function QuickHighlight() {
    const classes= useStyles();
    const [batches, setBatches] = useState([]);
    const [filter, setFilter] = useState('');
    const [items, setItems] = useState([]);

    const handleFilterChange = (e) => setFilter(e.target.value);

    useEffect(() => {
        getBatches({ client: Session.userId })
            .then(({ batches }) => { setBatches(batches); })
            .catch((error) => { console.error(error); })
    }, []);

    useEffect(() => {
        setFilter(batches[0]?._id || '')
    }, [batches]);

    useEffect(() => {
        (async () => {
            const quickHighlights = await getQuickHiglights(filter);

            const _items = quickHighlights.map((qhData) => {
                const id = qhData.id;
                return { ...qhData, ...QuickHighlightDetails[id] }
            });

            setItems(_items);
        })()
    }, [filter])
    
    return (
        <Box width={'100%'}>
            <Box className={classes.batchHighlights}>
                <Box className={classes.batchHeading}>
                    <Typography variant="h4-medium" color='neutral.clr-800' sx={{marginRight:'4px'}}>
                        Batch highlights
                        <img 
                          src="https://assets.languify.in/images/Stars.svg"
                          alt="sparkles"
                          width={32}
                          height={32}
                          className={classes.sparkles}
                        />
                    </Typography>
                    <Typography variant="h6-medium" color='neutral.clr-600'>
                        Take a look at your students’ performance
                    </Typography>
                </Box>
                <SimpleSelect
                    options={batches}
                    value={filter}
                    disabled={batches.length === 0}
                    onSelect={handleFilterChange}
                    label='Select batch'
                />
            </Box>

            {
                (items.length) ?
                    <CardCarasoul items={items} /> :
                    <Box className={classes.unlockCard}>
                        <img 
                          width='72px' height='72px' 
                          src={'https://assets.languify.in/gifs/magicBall.gif'} 
                          alt="lock"
                        />
                        <Box className={classes.content}>
                            <Typography variant='h5-semiBold' color='neutral.clr-900'>
                                Unlock Highlights
                            </Typography>
                            <Typography variant='body01-semiBold' color='neutral.clr-700'>
                                Highlights are almost there! We just need 5 students to take an
                                attempt in this batch. Simple, right? 
                                <br/>Help us out – ask your students
                                to take more attempts and let the insights flow!
                            </Typography>
                        </Box>
                    </Box>
            }

        </Box>
    )
}




export default QuickHighlight