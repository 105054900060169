import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    items: {
        fontSize: 14,
        fontWeight: 400,
        padding: '4px 10px',
        '&:hover': {
            backgroundColor: '#02569D33'
        }
    }
});

export default function CustomMenu({ params, height, options = [] }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation(); 
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: height || 200,
                        width: 'fit-content',
                        backgroundColor: '#F1F2F6',
                        borderRadius: 8,
                    },
                }}
                onClick={(event) => event.stopPropagation()} 
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={(event) => {
                            event.stopPropagation(); 
                            handleClose(event);
                            option?.onClick(event, params); 
                        }}
                        className={classes.items}
                    >
                        {option?.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
