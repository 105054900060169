import React from 'react';
import Box from '@mui/material/Box';
import MUITextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/styles';
import { debounce } from 'lodash';

const CustomIconButton = styled(IconButton)({
  cursor: 'default',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'block',
    borderRadius: theme.spacing(1),
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor : theme.palette.neutral['clr-600']
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '16px',
      color: `${theme.palette.neutral['clr-800']} !important`,
      height: 'fit-content',
      ...theme.typography['body01-medium'],
      padding: theme.spacing(3,5),
    },
    '& .MuiOutlinedInput-root': {
      '&:hover' : {
        backgroundColor: theme.palette.neutral['clr-50']
      },
      height: '44px !important',
      borderRadius: theme.spacing(1),
      fontSize: 16,
      '& fieldset': {
        borderColor: theme.palette.neutral['clr-500'],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.neutral['clr-500'],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary['clr-300'],
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral['clr-200'],
        '& fieldset': {
          border: '2px solid',
          borderColor: theme.palette.neutral['clr-400'],
        },
      }
    },
  },
  textArea: {
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(0),
    },
    '& .MuiOutlinedInput-root': {
      height: 'min-content !important'
    }
  },
  searchField: {
    '& .MuiOutlinedInput-input': {
      paddingLeft: '0px',
      paddingRight: theme.spacing(5)
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px'
    },
    '& .MuiInputAdornment-root': {
      padding: '0px', margin: '0px'
    }
  },
  autoCompleteField: {
    '& .MuiOutlinedInput-input': {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(5)
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px'
    },
    '& .MuiInputAdornment-root': {
      padding: '0px', margin: '0px'
    }
  },
  errorTxtField: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input': {
        color: theme.palette.danger.main
      },
      '& fieldset': {
        borderColor: theme.palette.danger.main,
      },
    },
  },
  successTxtField: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input': {
        color: theme.palette.success.main
      },
      '& fieldset': {
        borderColor: theme.palette.success.main,
      },
    },
  },
  supportiveTxt: {
    display: 'block',
    marginTop: theme.spacing(1),
    ...theme.typography['body02-medium'],
    color: theme.palette.neutral['clr-400'],
  },
  label: {
    ...theme.typography['body01-bold'],
    textTransform: 'capitalize', marginBottom: theme.spacing(2)
  },
  iconStyle:{
    padding:theme.spacing(3,3,1.5,5),
  }
}));

export default function TextField({ 
  error = null, success = null, supportingText = null, label = null, 
  onChange=()=>{}, value, width ,...props 
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box>
      {
        label && <Typography className={classes.label}>{label}</Typography>
      }
      <MUITextField
        size='small'
        className={
          `
            ${classes.textField} 
            ${error ? classes.errorTxtField : success ? classes.successTxtField : ''}
          `
        }
        variant="outlined"
        spellCheck="false"
        onChange={onChange}
        value={value}
        fullWidth
        style={{
          minWidth: 100,
          width: width,
          ...(props.sx ? { ...props.sx } : {}),
        }}
        InputProps={
          error ? 
          {endAdornment: (
            <InputAdornment position="end">
              <WarningAmberOutlinedIcon fontSize='small' style={{color:theme.palette.danger.main}}/>
            </InputAdornment>
          )}

          : success ? 
            {endAdornment: (
              <InputAdornment position="end">
                <CheckCircleOutlinedIcon fontSize='small' style={{color:theme.palette.success.main}}/>
              </InputAdornment>
            )} : null
        }
        {...props}
      />
      {
        error && 
          <Typography 
            className={ classes.supportiveTxt }
            style={{ color: theme.palette.danger.main}}
          >
            {error}
          </Typography>
      }
      {
        success && 
        <Typography 
            className={ classes.supportiveTxt }
            style={{ color: theme.palette.success.main}}
          >
            {success}
          </Typography>
      }
      {
        supportingText && 
          <Typography className={ classes.supportiveTxt }>{supportingText}</Typography>
      }
    </Box>
  );
}

export function SearchTextField({
  placeholder = '', label, onChange=()=>{}, value,...props
}) {
  const classes = useStyles();

  return (
    <Box>
      {
        label && <Typography className={classes.label}>{label}</Typography>
      }
      <MUITextField
        size='small'
        className={`${classes.textField} ${classes.searchField}`}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        variant="outlined"
        fullWidth
        style={{
          minWidth: 100,
          ...(props.sx ? { ...props.sx } : {}),
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CustomIconButton sx={{marginRight:'4px'}}>
                <img 
                  src='https://assets.languify.in/images/search-icon.svg' 
                  alt='search' 
                  width={16}
                  height={16}
                />
              </CustomIconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </Box>
  );
}

export function AutoCompleteField({
  placeholder = '', value, label, onChange=()=>{}, clearField=()=>{}, ...props
}) {
  const classes = useStyles();

  return (
    <Box>
      {
        label && <Typography className={classes.label}>{label}</Typography>
      }
      <MUITextField
        size='small'
        className={`${classes.textField} ${classes.autoCompleteField}`}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        variant="outlined"
        fullWidth
        style={{
          minWidth: 100,
          ...(props.sx ? { ...props.sx } : {}),
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={clearField}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </Box>
  );
}

export function PasswordField({
  error = null, helperText=null, label = null, 
  onChange=()=>{}, value, ...props 
}) {
  const classes = useStyles();
  const theme = useTheme();
  
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
  };

  return (
    <>
      <Box>
      {
        label && <Typography className={classes.label}>{label}</Typography>
      }
      <MUITextField
        size='small'
        className={
          `
            ${classes.textField} 
            ${error ? classes.errorTxtField : ''}
          `
        }
        variant="outlined"
        spellCheck="false"
        onChange={onChange}
        value={value}
        fullWidth
        style={{
          minWidth: 100,
          ...(props.sx ? { ...props.sx } : {}),
        }}
        type={showPassword ? "text" : "password"}
        sx={{...(props.sx ? { ...props.sx } : {})}}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="small"
                    >
                        {showPassword ?
                            <VisibilityOff fontSize="small" /> :
                            <Visibility fontSize="small" />}
                    </IconButton>
                </InputAdornment>
            ),
            ...props.InputProps,
        }}
        {...props}
      />
      {
        error && 
          <Typography 
            className={ classes.supportiveTxt }
            style={{ color: theme.palette.danger.main, marginTop: '5px' }}
          >
            {helperText || 'Required' }
          </Typography>
      }
    </Box>
    </>
  );
};

export function TextArea({ 
  error = null, success = null, ...props 
}) {
  const classes = useStyles();

  return (
      <TextField
        multiline
        className={
          `
            ${classes.textField} ${classes.textArea}
            ${error ? classes.errorTxtField : success ? classes.successTxtField : ''}
          `
        }
        error={error}
        success={success}
        {...props}
      />
  );
};

export function DebouncedTextField({
  label = "",
  placeholder = "Search...",
  onChange,
  InputProps,
  startIcon,
  endIcon,
  ...props
}) {
  const classes = useStyles()

  const handleChange = (e) => onChange(e.target.value);

  const handleInput = debounce(handleChange, 500);

  return (
    <SearchTextField
      {...props}
      label={label}
      placeholder={placeholder}
      sx={{ maxWidth: props?.width || 200, ...props?.sx }}
      onInput={handleInput}
    />
  );
}

